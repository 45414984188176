import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`DevIQ is a reference site designed to help you learn about high-level software development topics like  domain-driven design, design patterns, and antipatterns.`}</p>
    <h2 {...{
      "id": "sections",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sections",
        "aria-label": "sections permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sections`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/design-patterns/design-patterns-overview"
        }}>{`Design Patterns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/practices/practices-overview"
        }}>{`Practices`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/principles/principles-overview"
        }}>{`Principles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/values/values-overview"
        }}>{`Values`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/antipatterns/antipatterns-overview"
        }}>{`Antipatterns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "domain-driven-design/ddd-overview"
        }}>{`Domain Driven Design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tools/tools-overview"
        }}>{`Tools`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/terms/terms-overview"
        }}>{`Terms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/testing/testing-overview"
        }}>{`Testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/laws/laws-overview"
        }}>{`Laws of Software Development`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/architecture/architecture-overview"
        }}>{`Architecture`}</a></li>
    </ul>
    <p>{`Looking for training courses previously available at app.deviq.com? Contact us at `}<a parentName="p" {...{
        "href": "https://nimblepros.com/"
      }}>{`NimblePros for training options`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      